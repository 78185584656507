import { extendObservable, action } from 'mobx';
import { RootStore } from '../RootStore';
import { JsonRpcProvider, JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { NETWORK, INFURA_ID, contractAbi, CONTRACT_ADDRESS } from '../../config/constants';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { SignatureData } from '../../config/interfaces';

export class WalletStore {
	private store: RootStore;
	public provider: Web3Provider | JsonRpcProvider | null;
	public signer: ethers.Wallet | JsonRpcSigner | null;
	public modalProvider: any | null;
	public address: string | null;
	public contract: ethers.Contract | null;
	public gasData: SignatureData | null;
	private web3Modal: Web3Modal;

	constructor(store: RootStore) {
		this.store = store;
		this.provider = null;
		this.signer = null;
		this.modalProvider = null;
		this.address = null;
		this.contract = null;
		this.gasData = null;
		this.web3Modal = new Web3Modal({
			network: NETWORK,
			cacheProvider: true,
			providerOptions: {
				walletconnect: {
					package: WalletConnectProvider,
					options: {
						infuraId: INFURA_ID
					}
				}
			}
		});

		extendObservable(this, {
			provider: this.provider,
			signer: this.signer,
			modalProvider: this.modalProvider,
			address: this.address,
			contract: this.contract,
			gasData: this.gasData
		});
	}

	setProvider = action(
		async (provider: Web3Provider | JsonRpcProvider): Promise<void> => {
			this.provider = provider;
			this.signer = provider.getSigner();
			this.address = await this.signer?.getAddress();
		}
	);

	setModalProvider = action((provider: any) => {
		this.modalProvider = provider;
	});

	getData = action(async () => {
		const localData = window.localStorage.getItem(`${this.address}-claimdata`);
		const localGasData = localData ? JSON.parse(localData) : null;
		if (!localGasData) {
			try {
				const data = await fetch(`https://claimgas.xyz/claim?address=${this.address}`);
				this.gasData = await data.json();
				if (this.gasData)
					window.localStorage.setItem(`${this.address}-claimdata`, JSON.stringify(this.gasData));
			} catch {
				console.error(`Error retrieving data for address ${this.address}`);
			}
		}
	});

	connect = async () => {
		try {
			this.web3Modal.clearCachedProvider();
			const modalProvider = await this.web3Modal.connect();
			if (modalProvider.isMetaMask) {
				await this.setProvider(new Web3Provider(modalProvider));
			} else {
				await this.setProvider(new JsonRpcProvider(modalProvider));
			}
			this.setModalProvider(modalProvider);
			if (this.signer) {
				this.contract = new ethers.Contract(CONTRACT_ADDRESS, contractAbi(), this.signer);
			}
			await this.getData();

			this.modalProvider.on('accountsChanged', async (accounts: string[]) => {
				await this.setProvider(new Web3Provider(modalProvider));
				if (this.signer) {
					this.contract = new ethers.Contract(CONTRACT_ADDRESS, contractAbi(), this.signer);
				}
				await this.getData();
			});
		} catch (e) {
			console.error('error:', e);
		}
	};

	disconnect = async () => {
		await this.web3Modal.clearCachedProvider();
		this.signer = null;
		this.provider = null;
		this.address = null;
	};

	claim = async () => {
		if (!this.provider || !this.signer) {
			console.error('Error initiating Provider or Signer, please refresh and clear cache.');
		}

		const data = this.gasData;
		if (!data) {
			console.error('Error retrieving data signature data.');
			return;
		}
		// const data = await fetch(`https://claimgas.xyz/claim?address=${address}`)
		// 	.then(response => {
		// 		return response.json();
		// 	})
		// 	.catch(err => {
		// 		console.error(`Error retrieving data for address ${address}`);
		// 	});
		const claim = await this.contract?.claim(data.amountV, data.r, data.s);
		await claim.wait();
	};
}
