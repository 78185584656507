import { createContext } from 'react';
import { WalletStore } from './stores/WalletStore';

export class RootStore {
	public wallet: WalletStore;

	constructor() {
		this.wallet = new WalletStore(this);
	}
}

const store = new RootStore();

export const StoreContext = createContext({} as RootStore);
export const StoreProvider = StoreContext.Provider;

export default store;
