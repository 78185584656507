import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../mobx/RootStore';
import Button from '@mui/material/Button';

const shortenAddress = (address: string | null) => {
	if (!address) {
		return 'Connect';
	}
	return address.slice(0, 4) + '..' + address.slice(address.length - 4, address.length);
};

const WalletButton = observer(() => {
	const store = useContext(StoreContext);
	const { wallet } = store;

	async function connect(): Promise<void> {
		if (!wallet) return;

		if (wallet.provider) {
			await wallet.disconnect();
		} else {
			try {
				await wallet.connect();
			} catch (error) {
				console.error(error);
			}
		}
	}

	return (
		<Button
			style={{ marginRight: '2rem', color: 'white' }}
			variant="outlined"
			onClick={async () => {
				await connect();
			}}
		>
			{shortenAddress(wallet.address)}
		</Button>
	);
});

export default WalletButton;
