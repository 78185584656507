import { observer } from 'mobx-react-lite';
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chart from './Chart3.png';

const About = observer(() => {
	return (
		<Box m={20}>
			<Container>
				<Typography component="h1" variant="h2" align="center" color="#Dfeaff" gutterBottom>
					<b>About</b>
				</Typography>
				<Typography component="p" align="left" color="white" gutterBottom>
					$GAS is a token built to reimburse all of the users of the eth blockchain who have spent their
					valuable gas to operate, while also trying to improve the chain itself. We are working to
					#MakeTransactionsCheapAgain.
				</Typography>
				<br></br>
				<br></br>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={6}>
						<Typography component="h3" variant="h4" align="center" color="white" gutterBottom>
							DISTRIBUTION
						</Typography>
						<br></br>
						<Typography component="p" align="left" color="white" gutterBottom>
							Total of : 100,000,000,000,000 $GAS
							<br />
							<br />
							<img alt="chart" src={Chart} width="80%" />
						</Typography>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Typography component="h3" variant="h4" align="center" color="white" gutterBottom>
							AIRDROP
						</Typography>
						<br></br>
						<Typography component="p" align="left" color="white" gutterBottom>
							60% of the tokens will be reserved to the airdrop for the users of the Ethereum blockchain
							who have spent their valuable gas in 2021. Snapshot was for all gas spent/failed between block 11565019 and 13884540, or Jan 1, 2021 - Dec 26, 2021.
							<br></br>
							<br></br>
							Distributions will be based on how much ETH you've spent on GAS. This includes any
							transaction that has been done an a dApp, transfers between wallets, and more. There will be
							a weighted distribution based on the following:
							<br></br>
							<br></br> <b>Successful Transactions</b>: &#8730;(gas fees) x 100m
							<br></br> <b>Failed Transactions</b>: Failed Transaction Fees x 100m
						</Typography>
					</Grid>
				</Grid>
				<br></br>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={6}>
						<Typography component="h3" variant="h4" align="center" color="white" gutterBottom>
							GasDAO
						</Typography>
						<br></br>
						<Typography component="p" align="left" color="white" gutterBottom>
							15% of the tokens will be reserved for the DAO
							<br></br>
							<br></br>- Funding bounties and grants on Gitcoin to incentivize building around gas
							efficiency.
							<br></br>- Supporting projects that utilize gas saving contracts.
							<br></br>- Build $GAS community.
						</Typography>
					</Grid>
					<Grid item xs={12} lg={6}>
						<Typography component="h3" variant="h4" align="center" color="white" gutterBottom>
							LP INCENTIVES
						</Typography>
						<br></br>
						<Typography component="p" align="left" color="white" gutterBottom>
							20% of the tokens will be reserved for rewarding LP providers:
							<br></br>
							<br></br>
							These tokens will be used as emissions to reward users for providing LP in the early stages
							of this project
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
});

export default About;
