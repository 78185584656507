import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { StoreContext } from '../mobx/RootStore';
import { Button } from '@material-ui/core';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Socials from './Socials';
// @ts-ignore
import BigNumber from 'bignumber.js';

const comma = (x: any) => {
	var parts = x.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	return parts.join('.');
};

const formatter = (num: number | string) => {
	num = Number(num);
	return num > 999999 ? (num / 1e6).toFixed(3) + ' million' : num;
};

const GasData = observer(() => {
	const { wallet } = useContext(StoreContext);

	const nOut = wallet.gasData?.txData.nOut;
	const nOutFail = wallet.gasData?.txData.nOutFail;
	const gasFeeTotal = wallet.gasData?.txData.gasFeeTotal;
	const gasFeeTotalFail = wallet.gasData?.txData.gasFeeTotalFail;
	const ethusd = wallet.gasData?.txData.ethusd;
	const gasUsedTotal = wallet.gasData?.txData.gasUsedTotal;
	const gasPriceTotal = wallet.gasData?.txData.gasPriceTotal;
	const claimAmount = wallet.gasData ? new BigNumber(wallet.gasData?.txData.claimAmount) : null;
	return (
		<Box m={15} color="success.main">
			<Container maxWidth="lg">
				<Typography component="h1" variant="h2" align="center" color="#Dfeaff" gutterBottom>
					<b>$GAS DAO</b>
				</Typography>
				<Typography component="h4" align="center" color="white" gutterBottom>
					<h3>
						You've spent{' '}
						<span style={{ color: 'red' }}>
							Ξ{gasFeeTotal ? comma((gasFeeTotal / 1e18).toFixed(3)) : '...'}
						</span>{' '}
						on gas in 2021. Right now, that's
						<span style={{ color: 'red' }}>
							{' '}
							{ethusd && gasFeeTotal
								? '$' + comma(formatter(((ethusd * gasFeeTotal) / 1e18).toFixed(2)))
								: '...'}
						</span>
						.
					</h3>
					<h3>
						You used{' '}
						<span style={{ color: 'red' }}>{gasUsedTotal ? comma(formatter(gasUsedTotal)) : '...'}</span>{' '}
						gas to send <span>{nOut ? comma(nOut) : '...'}</span> transactions, with an average price of{' '}
						<span style={{ color: 'red' }}>
							{gasPriceTotal && nOut ? comma((gasPriceTotal / nOut / 1e9).toFixed(1)) : '...'}
						</span>{' '}
						gwei.
					</h3>
					<h3>
						<span style={{ color: 'red' }}>{nOutFail ? comma(nOutFail) : '...'}</span> of them failed,
						costing you {''}
						<span style={{ color: 'red' }}>
							Ξ{gasFeeTotalFail ? (gasFeeTotalFail / 1e18).toFixed(3) : '...'}
						</span>
						.
					</h3>
					<h3> Ethereum gas looks like it's taken a toll on you, and you deserve something in return.</h3>
					<h3>
						{' '}
						Here's{' '}
						<span style={{ color: 'red' }}>
							{claimAmount ? comma(claimAmount.toFixed(0).toString()) : '...'}
						</span>{' '}
						$GAS tokens! No hidden fees or taxes!
					</h3>
					<h3>
						The minimalist style of our site is an homage to{' '}
						<a style={{ textDecoration: 'none', color: '#3f51b5' }} href="https://fees.wtf/">
							fees.wtf
						</a>{' '}
						which we've used countless times over the past year
					</h3>
					<Button
						variant="contained"
						color="primary"
						disabled={!wallet.provider}
						onClick={() => {
							wallet.claim();
						}}
					>
						Claim $GAS
					</Button>
				</Typography>
				<Socials />
			</Container>
		</Box>
	);
});

export default GasData;
