import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Twitter from './twitter.png';
import Discord from './discord.png';
import Etherscan from './etherscan.png';

const Socials = observer(() => {
	return (
		<Grid container justifyContent={'center'} paddingTop={'3rem'}>
			<Grid item textAlign={'center'} paddingRight={'1rem'}>
				<a href="https://twitter.com/claimgasxyz">
					<img alt="Twitter" src={Twitter} />
				</a>
			</Grid>
			<Grid item textAlign={'center'} paddingRight={'.5rem'} paddingLeft={'.5rem'}>
				<a href="https://discord.gg/gasdao">
					<img alt="Discord" src={Discord} />
				</a>
			</Grid>
			<Grid item textAlign={'center'} paddingLeft={'1rem'}>
				<a href="https://etherscan.io/address/0xB7621435C7DCe99a529132fd59F61aB9981a899C">
					<img alt="Etherscan" src={Etherscan} />
				</a>
			</Grid>
		</Grid>
	);
});

export default Socials;
