import Gasdata from './components/GasData';
import About from './components/About';

import { styled } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import WalletButton from './components/WalletButton';

const Header = styled(Container)(({ theme }) => ({
	maxWidth: '100%',
	backgroundColor: '#282c34',
	minHeight: '70px',
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'flex-end',
	color: 'white'
}));

const Body = styled(Container)(({ theme }) => ({
	maxWidth: '100%',
	alignItems: 'center',
	backgroundColor: '#282c34',
	color: 'white',
	display: 'flex',
	flexDirection: 'column',
	fontSize: 'calc(10px + 2vmin)',
	justifyContent: 'center',
	minHeight: 'calc(100vh - 70px)'
}));

function App() {
	return (
		<>
			<Header>
				<WalletButton />
			</Header>
			<Body>
				<Gasdata />
				<About />
			</Body>
		</>
	);
}

export default App;
