import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import store from './mobx/RootStore';
import { StoreProvider } from './mobx/RootStore';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './config/theme';
import App from './App';

ReactDOM.render(
	<StoreProvider value={store}>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</StoreProvider>,
	document.getElementById('root')
);
